<template>
  <!-- 订单详情页面 -->
  <div id="ReservationDetails">
    <van-nav-bar title="订单详情" left-arrow @click-left="goLastPage" :border="false" />
    <ProductInfor buttonText="确认完工" @buttonClick="goSubPage('affirm-finished',$route.params.orderId)" />
  </div>
</template>
<script>
import ProductInfor from "@/components/order/ProductInfor.vue";
export default {
  data() {
    return {};
  },
  components: {
    ProductInfor,
  },
  methods: {},
  created() {},
};
</script>
<style lang="less">
</style>